export default defineNuxtRouteMiddleware(async () => {
  const { authenticated } = useAuth()
  const { fetchSignatoryProperties } = useUserHouses()
  const { fetchContracts } = useUserContracts()

  if (authenticated.value) {
    try {
      await Promise.all([fetchSignatoryProperties(), fetchContracts()])
    } catch (err) {
      console.error(err)
    }
  }
})
